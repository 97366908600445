import React from 'react';
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import RoboxDayBackground from '../assets/img/backgrounds/RoboxDay.png';
import RoboxMakerLogo from '../assets/img/RoboxMakerLogo.png';
import AkerLogo from '../assets/img/AkerLogo.png';
import VertexStudioLogo from '../assets/img/VertexStudioLogo.png';
import MicrosoftLogo from '../assets/img/MicrosoftLogo.png';
import RobotRight from '../assets/img/RobotRight.png';

import RoboxDayForm from '../containers/RoboxDayForm';	
import Indications from './RoboxDayInstructions';

import { 
    leftToInitial,
    container_delay_childrens_1_3,    
    item_showHidden,
 } from '../assets/js/FramerMotionAnimations.js';

import R_Letter from '../assets/img/neon_letters/R.png';
import O_Letter from '../assets/img/neon_letters/O.png';
import B_Letter from '../assets/img/neon_letters/B.png';
import O2_Letter from '../assets/img/neon_letters/O2.png';
import X_Letter from '../assets/img/neon_letters/X.png';

import Underscore_Letter from '../assets/img/neon_letters/Underscore.png';

import D_Letter from '../assets/img/neon_letters/D.png';
import A_Letter from '../assets/img/neon_letters/A.png';
import Y_Letter from '../assets/img/neon_letters/Y.png';

const RoboxDay = (props) => {
    const [lg, i18n] = useTranslation("global");
    const { Mobile, Tablet, Desktop } = props;
    
    return (
        <div>
            <motion.div style={{background: `url(${RoboxDayBackground})`, backgroundPosition: "initial" }} className="section" id="robox-day">
                <div className="container robox-day text-white" style={{paddingTop: 120, paddingBottom: 150, overflow: "hidden"}}>
                    <motion.div className="row d-flex justify-content-center text-center" initial="initial" whileInView="animate" viewport={{ once: true }}>
                        <HeaderRoboxDay 
                            Header1={lg('robox-day.header-1')}
                            Header2={lg('robox-day.header-2')}
                            Mobile={Mobile} 
                        />
                        <section className="col-10 col-sm-8 col-md-12 mt-5">
                            <motion.div className="row d-flex justify-content-center" variants={container_delay_childrens_1_3}>
                                <DateTimeLayout 
                                    Date="04"
                                    Month="OCT."
                                    HourStart="9"
                                    MinutesStart="00"
                                    HourFinish="11"
                                    MinutesFinish="00"
                                    FirstItem={true}
                                    Mobile={Mobile}
                                />
                                <DateTimeLayout 
                                    Date="05"
                                    Month="OCT."
                                    HourStart="8"
                                    MinutesStart="00"
                                    HourFinish="11"
                                    MinutesFinish="00"
                                    FirstItem={false}
                                    Mobile={Mobile}
                                />
                            </motion.div>                            
                        </section>
                        <motion.section className="col-11 col-sm-8 col-md-12 col mt-5" variants={container_delay_childrens_1_3}>
                            <div className="row d-flex justify-content-center align-items-center" >
                                <section className="col">
                                    <div className="column d-flex justify-content-center align-items-center">
                                        <div className="location-legend d-flex align-items-center">
                                            <i className="fa-solid fa-location-dot fa-2x" style={{marginLeft: 5, marginRight: 5}} />
                                            <span className="location-text">
                                                <b>{lg("robox-day.location-text")}</b>
                                            </span>
                                        </div>                                     
                                    </div>                                                                     
                                </section>
                            </div>
                        </motion.section>
                        <motion.section className="col-10 col-sm-12 col-md-12 col-lg-12 mt-5" variants={container_delay_childrens_1_3}>
                            <div className="row">
                                {/* Empty Side */}
                                <section className="col-3
                                d-none d-sm-block 
                                d-sm-none d-md-block
                                d-md-none d-lg-block">                                    
                                </section>
                                {/* RoboxMaker solid logo */}
                                <section className="col-12
                                d-md-none d-lg-block
                                d-lg-none d-xl-block
                                d-xl-none">
                                    <p className="launch-text">{lg("robox-day.launch-text")}</p>                                            
                                    <img src={RoboxMakerLogo} className="robox-maker-logo-launch img-fluid" alt="RoboxMaker Logo Launch" />
                                </section>
                                {/* Roboxmaker launch information */}
                                <section className="col">
                                    <div className="row">
                                        <motion.div className="col-12
                                        d-none d-sm-block
                                        d-sm-none d-md-block" variants={item_showHidden}>
                                            <p className="launch-text ">{lg("robox-day.launch-text")}</p>
                                                <img src={RoboxMakerLogo} className="robox-maker-logo-launch img-fluid" alt="RoboxMaker Logo Launch" />                                            
                                        </motion.div>
                                        <section className="col-12">
                                            <div className="row d-flex justify-content-center align-items-center">
                                                <motion.section className="col-12" variants={item_showHidden}>
                                                    {/* RobotRight position in mobile/tablet mode */}
                                                        <img src={RobotRight} className="img-fluid floating-img 
                                                            d-md-none d-lg-block
                                                            d-lg-none d-xl-block
                                                            d-xl-none mt-5 mb-5" alt="VertexStudio Logo" />                                           
                                                    <p className="organized-by-tex">{lg("robox-day.organize-text")}</p>
                                                </motion.section>
                                                <section className="col-6 col-sm-6 col-md col-lg-4 border-end">
                                                    <motion.img src={MicrosoftLogo} className="img-fluid vertexstudio-logo" alt="VertexStudio Logo" variants={item_showHidden} />
                                                </section>                                                
                                                <section className="col-6 col-sm-6 col-md col-lg-4 aker-logo-container border-end" >
                                                    <motion.img src={AkerLogo} className="img-fluid aker-logo" alt="Aker Logo" variants={item_showHidden} />
                                                </section>
                                                <section className="col-6 col-sm-6 col-md col-lg-4" >
                                                    <motion.img src={VertexStudioLogo} className="img-fluid vertexstudio-logo" alt="VertexStudio Logo" variants={item_showHidden} />
                                                </section>
                                                <motion.section className="col-12 mt-5 mb-4" variants={item_showHidden}>
                                                    <a className="custom-button" href='#roboxday-form'>
                                                        {lg("robox-day.subscribe-btn")}</a>
                                                </motion.section>
                                            </div>
                                        </section>
                                    </div>
                                </section>
                                {/* Right Robot */}
                                <motion.section className="col-4
                                        d-none d-sm-block
                                        d-sm-none d-md-block float-area" variants={item_showHidden}>
                                        <img src={RobotRight} className="img-fluid floating-img" alt="VertexStudio Logo" />                                                               
                                </motion.section>
                            </div>
                        </motion.section>
                    </motion.div>                            
                    <RoboxDayForm Mobile={Mobile} />
                    <Indications Mobile={Mobile} />
                </div>
            </motion.div>
        </div>
    )
}


// Header of Robox-Day animated text
const HeaderRoboxDay = (props) => {
    const [lg, i18n] = useTranslation("global");    
    const { Header1, Header2, Mobile } = props;
    return (
        <>
            <motion.section className="col-md-12 col-sm-8" variants={leftToInitial}>
                <span className="header-1" dangerouslySetInnerHTML={{__html: Header1}}></span><br />
                {
                    Header2 &&
                    (
                        <span className="header-2" dangerouslySetInnerHTML={{__html: Header2}}></span>
                    )
                }                
            </motion.section>
            <section className="row d-flex justify-content-center mt-5">
                <div className="col-12 col-sm-12 col-md-10 col-lg-10 neon-text-container">
                    <div className="row d-flex justify-content-center align-items-center neon-text">                        
                        {[R_Letter, O_Letter, B_Letter, O2_Letter, X_Letter].map((image) => (
                            <div className='col-2 col-md col-lg col-not-padding' key={image.toString()}>
                                <motion.img src={image} className="img-fluid letter-shadow" alt="Letter" variants={leftToInitial} />
                            </div>          
                        ))}
                        <Mobile>
                            <div style={{marginTop: 20}} />
                        </Mobile>
                        {[Underscore_Letter, D_Letter, A_Letter, Y_Letter].map((image) => (
                            <div className='col-2 col-md col-lg col-not-padding' key={image.toString()}>
                                <motion.img src={image} className="img-fluid letter-shadow" alt="Letter" variants={leftToInitial} />
                            </div>       
                        ))}                                                                                                                                               
                    </div>
                </div>
            </section>        
        </>
    )
}


// new component that returns date and time of event
const DateTimeLayout = (props) => {
    const { Date, Month, HourStart, MinutesStart, HourFinish, MinutesFinish, FirstItem, Mobile } = props;
    return (
        <>
            {/* if NOT first item in Mobile Mode, then add margin-top */}
            <Mobile>
                {
                    FirstItem ? (<></>)
                    : (
                        <>
                            <div className="mt-4" />
                        </>                        
                    )
                }
            </Mobile>
            {/* Render Date and Time of event */}
            <section className={`date-time-event col-12 col-lg-4 ${FirstItem && 'border-end'}`}>
                <motion.div className="row d-flex justify-content-center" variants={item_showHidden}>
                    <section className="col-12">
                        <span className="date-title">
                            <span className="text-primary">
                                {Date}
                            </span>
                            <span className="text-white">
                                {Month}
                            </span>                                     
                        </span>
                    </section>
                    <section className="col-12 col-sm-10 col-md-5 col-lg-5">
                        <div className="mt-1 mb-1 horizontal-line-divider" />
                    </section>   
                    <section className="col-12">
                        <span className="time-subtitle tetx-white">
                            <span>{HourStart}</span><span>:</span><span>{MinutesStart}</span><small>am</small>      
                            <span>/</span>
                            <span>{HourFinish}</span><span>:</span><span>{MinutesFinish}</span><small>am</small>                                                                                                               
                        </span>                      
                    </section>                                                                                                                                                                                          
                </motion.div>
            </section> 
        </>
    )
}

export {RoboxDay, HeaderRoboxDay}