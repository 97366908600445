import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import TypeIt from "typeit-react";
import $ from 'jquery';

import HomeBackground from '../assets/img/backgrounds/Homepage.png';
import LaptopSet from '../assets/img/LaptopSet.png';
import LaptopOnly from '../assets/img/LaptopOnly.png';

// import OrangeConnector from '../assets/img/pieces/OrangeConnector.png';
// import YellowConnector from '../assets/img/pieces/YellowConnector.png';
// import WhiteAxis from '../assets/img/pieces/WhiteAxis.png';
// import GreenBall from '../assets/img/pieces/GreenBall.png';
// import RedConnector from '../assets/img/pieces/RedConnector.png';

import { 
    container_delay_childrens_1,
    container_delay_childrens_1_6,
    item_showHidden,    
 } from '../assets/js/FramerMotionAnimations.js';

const Homepage = (props) => {
    const { Mobile, Tablet, Desktop, FullDesktop } = props;
    const [lg, i18n] = useTranslation("global");           

    return (
        <div>                          
            <motion.div style={{background: `url(${HomeBackground})`, backgroundPosition: "bottom" }} className="section" id="homepage">
                <div className="container" style={{paddingTop: 150, paddingBottom: 150}}>
                    <motion.div className="row d-flex justify-content-center" initial="initial" whileInView="animate" viewport={{ once: true }}>
                        {/* Left Headers */}
                        <motion.div className="col-10 col-sm-7 col-md-6 col-lg-5" style={{zIndex: 1}} variants={container_delay_childrens_1}>
                            <div className="row">
                                <div className="col-12">
                                    <motion.div variants={item_showHidden} style={{display: lg("key.language") === 'es' ? "block" : "none"}}>                                        
                                        <TypeIt className="main-header-text"                                 
                                            options={{
                                                strings: ["¡SUEÑA EN GRANDE!          "],
                                                speed: 250,
                                                waitUntilVisible: false,
                                                loop: true, 
                                                breakLines: false,
                                                cursorChar: "<b style='text-white'>|</b>",
                                                loopDelay: 3000,
                                                html: true,
                                            }}
                                        />                                                    
                                    </motion.div>     
                                    <motion.div variants={item_showHidden} style={{display: lg("key.language") === 'en' ? "block" : "none"}}>                                        
                                        <TypeIt className="main-header-text"                                 
                                            options={{
                                                strings: ["DREAM BIG!          "],
                                                speed: 250,
                                                waitUntilVisible: false,
                                                loop: true, 
                                                breakLines: false,
                                                cursorChar: "<b style='text-white'>|</b>",
                                                loopDelay: 3000
                                            }}
                                        />                                                    
                                    </motion.div>                                                                                                                                           
                                </div>                              
                                <motion.div className="col-12 col-lg-10 mt-0 small-text-center" variants={item_showHidden}>
                                    <br />
                                    <span className="home-title-text" dangerouslySetInnerHTML={{__html: lg('home.title')}} /> <br />
                                        <div className="col-12 col-sm-5 col-md-6 col-lg-7">
                                            <Mobile>
                                                <motion.img variants={item_showHidden} src={LaptopOnly} className="w-100" alt="Laptop Set" />
                                            </Mobile>
                                        </div>                                                                          
                                    <span className="home-subtitle-text" dangerouslySetInnerHTML={{__html: lg('home.subtitle')}} /><br />
                                    <br />
                                    <a className="custom-button" href="#education">{lg("home.know-more-btn")}</a>       
                                    
                                                                              
                                </motion.div>                                  
                            </div>                                                                                    
                        </motion.div>
                        {/* Right floating pieces or laptop-set image */}
                        <motion.div className="col-12 col-sm-5 col-md-6 col-lg-7" variants={container_delay_childrens_1_6}>
                            <Desktop>
                                <motion.img variants={item_showHidden} src={LaptopSet} className="w-100" alt="Laptop Set" />
                            </Desktop>
                            <FullDesktop>
                                <motion.img variants={item_showHidden} src={LaptopSet} className="w-100" alt="Laptop Set" />
                            </FullDesktop>
                            
                            {/* <div className="icons">
                                <motion.div className="icon-container yellow-connector-piece" variants={piecesFloatingShow}>
                                    <div className="icon" style={{width:50, height:50}}>
                                        <Mobile>
                                            <img src={WhiteAxis} style={{width: '700px', marginTop: -150, marginLeft: 200}} className="home-white-and-yellow-axis" alt="WhiteAxis" />
                                        </Mobile>
                                        <Tablet>
                                            <img src={YellowConnector} style={{width: '700px', marginTop: -200, marginLeft: 90}} className="home-white-and-yellow-axis" alt="YellowConnector" />
                                        </Tablet>
                                        <Desktop>
                                            <img src={YellowConnector} style={{width: '700px', marginTop: -200, marginLeft: 90}} className="home-white-and-yellow-axis" alt="YellowConnector" />
                                        </Desktop>
                                    </div>
                                </motion.div>  
                                <motion.div className="icon-container red-connector-piece" variants={piecesFloatingShow}>
                                    <img src={RedConnector} style={{width: '240px', marginBottom: 200}} className="home-red-connector" alt="RedConnector" />                    
                                </motion.div>
                                <motion.div className="icon-container green-ball-piece" variants={piecesFloatingShow}>
                                    <img src={GreenBall} style={{width: '240px', marginTop: 400}} className="home-green-ball" alt="GreenBall" />
                                </motion.div>                                              
                            </div>                                */}
                        </motion.div>
                    </motion.div>
                </div>                
            </motion.div>      
        </div>
  )
}

export default Homepage