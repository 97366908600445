import React from 'react';
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import { HeaderRoboxDay } from './RoboxDay';

import NombreInput from '../assets/img/inputs/es/Nombre.png';
import CargoInput from '../assets/img/inputs/es/Cargo.png';
import ColegioInput from '../assets/img/inputs/es/Colegio.png';
import CorreoElectronicoInput from '../assets/img/inputs/es/CorreoElectrónico.png';
import NúmeroDeContactoInput from '../assets/img/inputs/es/NúmeroDeContacto.png';

import NameInput from '../assets/img/inputs/en/Name.png';
import PositionInput from '../assets/img/inputs/en/Position.png';
import SchoolInput from '../assets/img/inputs/en/School.png';
import EmailInput from '../assets/img/inputs/en/Email.png';
import ContactNumberInput from '../assets/img/inputs/en/ContactNumber.png';

import { 
    container_delay_childrens_1,
    item_showHidden,
 } from '../assets/js/FramerMotionAnimations.js';

const RoboxDayForm = (props) => {

    const [lg, i18n] = useTranslation("global");
    const { Mobile } = props;  

    return (
        <>
        
        <motion.div className="row d-flex justify-content-center text-center mt-5 mb-5" style={{paddingTop: 120, overflow: "hidden"}}
        initial="initial" whileInView="animate" viewport={{ once: true }} id="roboxday-form">
            <HeaderRoboxDay 
                Header1={lg('robox-day-form.header-1')}
                Header2={lg('robox-day-form.header-2')}
                Mobile={Mobile} 
            />
            <section className="col-12">
                <motion.form action="https://vertexstudio.us18.list-manage.com/subscribe/post?u=93ad9854e6f866c5d5d2d3cac&amp;id=f05d97aa2c&amp;f_id=000810e7f0" 
                method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" variants={container_delay_childrens_1}>
                <div className="row mt-5 d-flex justify-content-center">
                    <div className="col-12">
                        <motion.div className="custom-input-container" style={{background: `url(${ lg("key.language") === 'es' ? NombreInput : NameInput})`}} variants={item_showHidden}>
                            <div className='row d-flex justify-content-center'>
                                <span className="span-input mt-2">
                                    N/A
                                </span>
                                <input type="text" className="custom-input" name="NAME" id="mce-NAME" placeholder={lg("robox-day-form.name-ph")} required />
                                <span id="mce-NAME-HELPERTEXT" className="helper_text"></span>
                            </div>                        
                        </motion.div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-5">
                        <motion.div className="custom-input-container" style={{background: `url(${ lg("key.language") === 'es' ? CargoInput : PositionInput})`}} variants={item_showHidden}>
                            <div className='row d-flex justify-content-center'>
                                <span className="span-input mt-2">
                                N/A
                                </span>
                                <input type="text" name="POSITION" className="custom-input" id="mce-POSITION" placeholder={lg("robox-day-form.position-ph")} required />
                                <span id="mce-POSITION-HELPERTEXT" className="helper_text"></span>                                
                            </div>                        
                        </motion.div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-5">
                        <motion.div className="custom-input-container" style={{background: `url(${ lg("key.language") === 'es' ? ColegioInput : SchoolInput})`}} variants={item_showHidden}>
                            <div className='row justify-content-center'>
                                <span className="span-input mt-2">
                                N/A
                                </span>
                                <input type="text" name="SCHOOL" className="custom-input" id="mce-SCHOOL" placeholder={lg("robox-day-form.school-ph")} required />
                                <span id="mce-SCHOOL-HELPERTEXT" className="helper_text"></span>                                
                            </div>                        
                        </motion.div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-5">
                        <motion.div className="custom-input-container" style={{background: `url(${ lg("key.language") === 'es' ? CorreoElectronicoInput : EmailInput})`}} variants={item_showHidden}>
                            <div className='row justify-content-center'>
                                <span className="span-input mt-2">
                                N/A
                                </span>
                                <input type="email" name="EMAIL" className="required email custom-input" id="mce-EMAIL" placeholder={lg("robox-day-form.email-ph")} required />
                                <span id="mce-EMAIL-HELPERTEXT" className="helper_text"></span>
                            </div>                        
                        </motion.div>                        
                    </div>
                    <div className="col-12 col-md-6 col-lg-5">
                        <motion.div className="custom-input-container" style={{background: `url(${ lg("key.language") === 'es' ? NúmeroDeContactoInput : ContactNumberInput})`}} variants={item_showHidden}>
                            <div className='row justify-content-center'>
                                <span className="span-input  mt-2">
                                N/A
                                </span>
                                <input type="text" name="NUMBER" className="custom-input" id="mce-NUMBER" placeholder={lg("robox-day-form.phone-ph")} required />
                                <span id="mce-NUMBER-HELPERTEXT" className="helper_text"></span>                                
                            </div>                        
                        </motion.div>  
                    </div>
                    <div className="col-4">
                        <div id="mce-responses" className="clear foot">
                            <div className="response" id="mce-error-response" style={{display: "none"}}></div>
                            <div className="response" id="mce-success-response" style={{display: "none"}}></div>
                        </div>         
                        <div style={{display: "none", position:"absolute", left: '-5000px'}} aria-hidden="true">
                            <input type="text" name="b_93ad9854e6f866c5d5d2d3cac_f05d97aa2c" tabIndex="-1" value="" />
                        </div>
                        <motion.div className="optionalParent" variants={item_showHidden}>
                            <div className="clear foot">
                                <input type="submit" value={lg("robox-day-form.submit-btn")} name="subscribe" className="custom-button mt-3" />
                            </div>
                        </motion.div>   
                    </div>                            
                </div>
                </motion.form>
            </section>
        </motion.div> 
        </>
    )
}

export default RoboxDayForm