import React from 'react';
import Homepage from '../containers/Homepage';
import Education from '../containers/Education';
import {RoboxDay} from '../containers/RoboxDay';
import ModalInstructions from '../components/ModalInstructions';
import ModalRobotsViewer from '../components/ModalRobotsViewer';
import Navbar from '../components/Navbar';

const HomeStack = (props) => {

    const { Mobile, Tablet, Desktop, FullDesktop } = props;

    return (        
        <>
        <ModalRobotsViewer/>
        {/* <ModalInstructions />         */}
        <Navbar IsMainView={true} />     
            <Homepage Mobile={Mobile} Tablet={Tablet} Desktop={Desktop} FullDesktop={FullDesktop} />            
            <Education Mobile={Mobile} Tablet={Tablet} Desktop={Desktop} FullDesktop={FullDesktop} />            
            <RoboxDay Mobile={Mobile} Tablet={Tablet} Desktop={Desktop} FullDesktop={FullDesktop} />            
            {/* <br /><br /><br /><br /><br />
            <br /><br /><br /><br /><br />
            <br /><br /><br /><br /><br /> */}
        </>
    )
}

export default HomeStack;
