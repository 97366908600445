import React from 'react'
import ModalRobotsViewerBackground from '../assets/img/backgrounds/RoboxDay.png';
import Box from '../assets/img/box.png'

const ModalRobotsViewer = () => {
    const closeModal = () => {
        document.getElementById('modalRobotsViewer').style.display = 'none';
    }
    return (
        <div>
            <div id="modalRobotsViewer" className="modal-robots-viewer-container align-center">
                <div className="modal-robots-viewer-container-content text-white" 
                    style={{background: `url(${ModalRobotsViewerBackground})`, backgroundPosition: "center" }}>
                    <div className="row">
                        <div className='mr-3'>
                            <span onClick={() => closeModal()} className="modal-robots-viewer-close-btn" 
                                style={{marginRight: 10}}>&times;</span>
                        </div>
                        <div className="text-center pb-2">
                            <img src={Box} className="robox-maker-box" alt="Robox Maker Box" />
                            <h3 className="text-center mt-2 pb-3">¿Tienes una caja de Robox Maker?</h3>

                            <a type="button" href='##' className="custom-button-pink mb-3" 
                            onClick={() => closeModal()} 
                            style={{marginRight: 10,  backgroundColor: '#9257ff'}}>Aún No...</a>
                            <a type="button" className="custom-button" href='/vip'>
                                Sí, ¡Construyamos Un Robot!</a>                        
                        </div>
                    </div>
                </div>
            </div>        
        </div>
    )
}

export default ModalRobotsViewer