import React from 'react';
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import TypeIt from "typeit-react";

import PlatformBackground from '../assets/img/backgrounds/Platform.png';
import BorderCircleCyan from '../assets/img/BorderCircleCyan.png';
import RobotHello from '../assets/img/RobotHello.png';
import Legend from '../assets/img/Legend.png';
import DividerLine from '../assets/img/DividerLine.png';

import { 
    showHidden, 
    rotateCircle,
    leftTopToBottom, 
    rightToInitial
 } from '../assets/js/FramerMotionAnimations.js';

const Platform = (props) => {
    // Screen sizes props
    const { Mobile, Tablet, Desktop, FullDesktop } = props;
    // i18n change language
    const [lg, i18n] = useTranslation("global");

    return (
        <div>           
            <motion.div style={{background: `url(${PlatformBackground})`, backgroundPosition: "initial" }} className="section" id="education" >      
                <div className="container education" style={{paddingTop: 150, paddingBottom: 150, overflow: "hidden"}}>
                    <motion.div className="row d-flex justify-content-center" initial="initial" whileInView="animate" viewport={{ once: true }}>
                        {/* Left rotating image and animated typing text legend */}
                        <section className="col-10 col-sm-7 col-md-6 col-lg-5" style={{zIndex: 1}}>
                            <div className="row justify-content-center">
                                <div className="col-12">                                                             
                                    <motion.div className="d-flex justify-content-center align-items-center" variants={leftTopToBottom}>
                                        <motion.img src={BorderCircleCyan} className="border-circle" alt="Border Circle" variants={rotateCircle} initial="myhidden" animate="myvisible" />
                                        <motion.img src={RobotHello} className="img-fluid robot-hello" alt="Girl Building Robot" />
                                    </motion.div>
                                
                                </div>
                                <div className="col-12 text-white">
                                    <div className="mt-3 legend-platform-background d-flex justify-content-center align-items-center" style={{background: `url(${Legend})` }}>
                                        <motion.div className="legend-platform-container" variants={showHidden}>
                                                <div style={{display: lg("key.language") === 'es' ? "block" : "none"}}>                                                    
                                                    <TypeIt className="legend-platform-text"                                              
                                                        options={{
                                                            strings: ["La primera plataforma <span style='color: #fff'><b>Pedagógica Online de Robótica</b></span> en El Salvador <img src='https://cdn-icons-png.flaticon.com/512/330/330670.png' style='width: 40px' alt='El Salvador' />                                                  "],
                                                            speed: 50,
                                                            loop: true, 
                                                            breakLines: true,
                                                            cursorChar: "|",
                                                            html: true,
                                                            loopDelay: 5000
                                                        }}
                                                    />                                                                  
                                                </div>
                                                <div style={{display: lg("key.language") === 'en' ? "block" : "none"}}>                                                
                                                    <TypeIt className="legend-platform-text"                                               
                                                        options={{
                                                            strings: ["The first <span style='color: #fff'><b>Robotics Online Pedagogical</b></span> Platform in El Salvador <img src='https://cdn-icons-png.flaticon.com/512/330/330670.png' style='width: 40px' alt='El Salvador' />                                                  "],
                                                            speed: 50,
                                                            loop: true, 
                                                            breakLines: true,
                                                            cursorChar: "|",
                                                            html: true,
                                                            loopDelay: 5000
                                                        }}
                                                    />
                                                </div>                                                                                                                                    
                                        </motion.div>
                                    </div>
                                </div>
                            </div>                                                                                    
                        </section>
                        {/* Right items information */}
                        <section className="col-12 col-sm-12 col-md-6 col-lg-7 mt-4 text-white">
                            <div className="column h-100 justify-content-center align-items-center small-text-center">
                                <div className="row justify-content-center align-items-center">
                                    <motion.div className="col-11 col-sm-11 col-md-12 col-lg-6 p-2" variants={rightToInitial}>                                        
                                        <span className="item-title">
                                            {lg("education.item1-title")}</span>                                                 
                                    </motion.div>
                                    <motion.div className="col-11 col-sm-11 col-md-12 col-lg-6 p-2" variants={rightToInitial}>
                                        <span className="item-subtitle" dangerouslySetInnerHTML={{__html: lg('education.item1-description')}}>
                                        </span>
                                    </motion.div>
                                </div>
                                <img src={DividerLine} className="divider-line pt-3" alt="Divider Line" />
                                <div className="row justify-content-center align-items-center">
                                    <motion.div className="col-11 col-sm-11 col-md-12 col-lg-6 p-2" variants={rightToInitial}>
                                        <span className="item-title">
                                            {lg("education.item2-title")}</span>                                                 
                                    </motion.div>
                                    <motion.div className="col-11 col-sm-11 col-md-12 col-lg-6 p-2" variants={rightToInitial}>
                                        <span className="item-subtitle" dangerouslySetInnerHTML={{__html: lg('education.item2-description')}}>
                                        </span>
                                    </motion.div>
                                </div>                                
                            </div>
                        </section>
                    </motion.div>                     
                </div>               
            </motion.div>
        </div>
  )
}

export default Platform