import React from 'react';
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import RoboxDayBackground from '../assets/img/backgrounds/RoboxDay.png';
import { HeaderRoboxDay } from './RoboxDay';
import LeftInstructions from '../assets/img/LeftInstructions.png';
import RightInstructions from '../assets/img/RightInstructions.png';
import BottomInstructions from '../assets/img/BottomInstructions.png';

import AkerLogo from '../assets/img/AkerLogo.png';
import VertexStudioLogo from '../assets/img/VertexStudioLogo.png';
import MicrosoftLogo from '../assets/img/MicrosoftLogo.png';
import RobotRight from '../assets/img/RobotRight.png';


const Indications = (props) => {
    const [lg, i18n] = useTranslation("global");
    const { Mobile } = props;
        
    return (
        <div>
            <motion.div className="row d-flex justify-content-center text-center" style={{paddingTop: 100, overflow: "hidden"}}
                    initial="initial" whileInView="animate" viewport={{ once: true }} id="roboxday-indications">
                        <HeaderRoboxDay 
                            Mobile={Mobile}
                        />            
                    <div className="col-11 mt-5 pt-5" style={{backgroundColor: '#030468', borderRadius: 20}}>
                        <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-12">
                            <h1><b>{lg('robox-day-indications.header-1')}</b></h1>
                            <h2><b dangerouslySetInnerHTML={{__html: lg('robox-day-indications.header-2')}}></b></h2>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5">
                                <img src={LeftInstructions} className="img-fluid" alt="Left Instructions" style={{objectFit: 'cover'}} />
                            </div>
                            <div className="col-12 col-md-6 col-lg-5">
                                <img src={RightInstructions} className="img-fluid mt-3" alt="Right Instructions" style={{objectFit: 'cover'}} />
                            </div>
                            <div className="col-12 mt-3 pb-5">
                                <p className="organized-by-tex">{lg("robox-day.organize-text")}</p>
                                <div className="row d-flex justify-content-center align-items-center">                                
                                    <section className="col-6 col-sm-6 col-md col-lg-2 border-end">
                                        <motion.img src={MicrosoftLogo} className="img-fluid vertexstudio-logo" alt="VertexStudio Logo" />
                                    </section>                                                
                                    <section className="col-6 col-sm-6 col-md col-lg-2 aker-logo-container border-end" >
                                        <motion.img src={AkerLogo} className="img-fluid aker-logo" alt="Aker Logo" />
                                    </section>
                                    <section className="col-6 col-sm-6 col-md col-lg-2" >
                                        <motion.img src={VertexStudioLogo} className="img-fluid vertexstudio-logo" alt="VertexStudio Logo" />
                                    </section>
                                </div>                            
                            </div>
                        </div>
                    </div>                                   
            </motion.div>
        </div>
    )
}

export default Indications